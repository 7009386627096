import {createRouter, createWebHashHistory } from 'vue-router'
import Layout from "@/layout/Layout";


const routes = [
    {
        path: '/',
        name: 'Login',
        component: () => import("../views/Login")
    },
    {
        path: '/home',
        name: 'Layout',
        component: Layout,
        redirect: "/home",
        children:[
            {
                path: '/home',
                name: 'Home',
                component: () => import("@/views/Home"),
            },
            {
                path: '/password',
                name: 'Password',
                component: () => import("../views/Password")
            },
        ]
    },
    {
        path: '/fms',
        name: 'FileSystem',
        component: Layout,
        redirect: "/fms",
        children:[
            {
                path: '/file',
                name: 'File',
                component: () => import("../views/fms/File")
            },
            {
                path: '/cate',
                name: 'Category',
                component: () => import("../views/fms/Category")
            },
        ]
    },
    {
        path: '/ums',
        name: 'UserSystem',
        component: Layout,
        redirect: "/ums",
        children:[
            {
                path: '/user',
                name: 'User',
                component: () => import("../views/ums/User")
            },
            {
                path: '/u-login',
                name: 'UserLogin',
                component: () => import("../views/ums/UserLogin")
            },
            {
                path: '/u-search',
                name: 'UserSearch',
                component: () => import("../views/ums/UserSearch")
            },
            {
                path: '/u-pay',
                name: 'UserPay',
                component: () => import("../views/ums/UserPay")
            },
            {
                path: '/u-down',
                name: 'UserDown',
                component: () => import("../views/ums/UserDown")
            },
            {
                path: '/u-preview',
                name: 'UserPreview',
                component: () => import("../views/ums/UserPreview")
            },
            {
                path: '/setting',
                name: 'Setting',
                component: () => import("../views/ums/userSetting")
            },
        ]
    },
    {
        path: '/dms',
        name: 'Distribution',
        component: Layout,
        redirect: "/dms",
        children:[
            {
                path: '/dis-setting',
                name: 'DisSetting',
                component: () => import("../views/dms/DisSetting")
            },
            {
                path: '/dis-order',
                name: 'DisOrder',
                component: () => import("../views/dms/DisOrder")
            },
        ]
    },
    {
        path: '/sys',
        name: 'System',
        component: Layout,
        redirect: "/sys",
        children:[
            {
                path: '/admin',
                name: 'Admin',
                component: () => import("../views/sys/Admin")
            },
            {
                path: '/permission',
                name: 'Permission',
                component: () => import("../views/sys/Permission")
            },
            {
                path: '/role',
                name: 'Role',
                component: () => import("../views/sys/Role")
            },
        ]
    },

]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})


export default router
