import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/admin/list.php',
    method : 'post',
    data : data
  })
}

//删除
export function deleteAdmin(data) {
  return request({
    url : '/admin/delete.php',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteAdmin(data) {
  return request({
    url : '/admin/deleteBatch.php',
    method : 'post',
    data : data
  })
}

//新增
export function createAdmin(data) {
  return request({
    url : '/admin/saveAdmin.php',
    method : 'post',
    data : data
  })
}
//修改
export function updateAdmin(data) {
  return request({
    url :'/admin/updateAdmin.php',
    method : 'post',
    data : data
  })
}

//详情
export function getAdmin(data) {
  return request({
    url:'/admin/detail.php',
    method : 'post',
    data : data
  })
}

//修改密码
export function updatePwd(data) {
  return request({
    url : '/admin/updatePwd.php',
    method : 'post',
    data : data
  })
}

//重置管理员密码
export function resetAdminPass(data) {
  return request({
    url : '/admin/resetAdminPass.php',
    method : 'post',
    data : data
  })
}

//登录
export function adminLogin(data) {
  return request({
    url : '/admin/login.php',
    method : 'post',
    data : data
  })
}

