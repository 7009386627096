<template>
  <div style="height: 50px; line-height: 50px; border-bottom: 1px solid #ccc; display: flex">
    <div style="width: 200px; padding-left: 30px; font-weight: bold; color: dodgerblue">后台管理</div>
    <div style="flex: 1"></div>
    <div>
      <el-dropdown>
        <span class="el-dropdown-link" style="margin-right: 25px;">
          <el-avatar :size="30" :src="img_avatar" style="position: relative; top: 10px"></el-avatar>
           {{ user.nick_name }}
          <i class="el-icon-arrow-down el-icon--right" style="margin-left: 0px;"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="$router.push('/password')">修改密码</el-dropdown-item>
            <el-dropdown-item @click="$router.push('/')">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import img_avatar from '@/assets/images/user.jpg';
export default {
  name: "Header",
  props: ['user'],
  data() {
    return {
      img_avatar
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
