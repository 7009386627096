<template>
  <el-container class="layout-container">
    <el-header>
      <Header :user="user"/>
    </el-header>
    <el-container>
      <!--侧边栏-->
      <el-aside style="width: 200px">
        <Aside />
      </el-aside>
      <!--内容区域-->
      <el-main>
        <router-view style="flex: 1" @userInfo="refreshUser"/>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from "@/components/Header";
import Aside from "@/components/Aside";
import {getAdmin} from "@/api/admin";

export default {
  name: "Layout",
  components: {
    Header,
    Aside
  },
  data() {
    return {
      user: {}
    }
  },
  created() {
    this.refreshUser()
  },
  methods: {
    refreshUser() {
      let userJson = sessionStorage.getItem("user");
      if(!userJson){
        this.$message.warning("token过期，请重新登录！")
        this.$router.replace('/');
        return;
      }
      let userId = JSON.parse(userJson).id
      // 从后台取出更新后的最新用户信息
      let data =  {
        "dataId": userId,
      };
      getAdmin(data).then(response=>{
        if (response.code === '0') {
          this.user = response.data;
        }
      });
    }
  }
}
</script>

<style scoped>
.layout-container{
  height: 100%;
}

.el-header{
  padding: 0;
  height: 50px !important;
}

.el-aside{
  height: auto;
}

.el-main{
  padding: 0px;
  position: absolute;
  left: 200px;
  right: 0;
  top: 50px;
  bottom: 0;
  overflow-y: auto;
}
</style>
